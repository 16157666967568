import { FC, RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import FormHelper from '../components/form-helper/form-helper.component';
import { FormSchema } from '../models/schemas/form-schema';
import { BasicModalHandle, MultiSelectComponent, SelectComponent, utils } from '@truenorthmortgage/olympus';
import { SERVICES } from '../services';
import { UnderwritingService } from '../services/underwriting.service';

export interface CreateEditThinkerConditionProps {
    schema: FormSchema,
    onSubmit: (data: any) => void,
    parentRef?: RefObject<BasicModalHandle>
}

const CreateEditThinkerCondition: FC<CreateEditThinkerConditionProps> = ({ schema, onSubmit, parentRef }) => {
    const [selectedKey, setSelectedKey] = useState('');
    const [selectedValues, setSelectedValues] = useState<string[]>([]);
    const [valueOptions, setValueOptions] = useState<string[]>([]);

    const underwritingService = utils.injection.useInjection<UnderwritingService>(SERVICES.UnderwritingService);

    useEffect(() => {
        underwritingService.getSavvyyValuesByKey(selectedKey).then(setValueOptions);
    }, [selectedKey]);

    const onCustomPayload = useCallback(async () => {
        return {
            savvyy_key: selectedKey,
            savvyy_values: selectedValues
        };
    }, [selectedKey, selectedValues]);

    const updatedSchema = useMemo(() => {
        return Object.assign({}, schema, {
            components: schema.components.map((component, index) => {
                if (component.type === 'select' && component.name === 'savvyy_key') {
                    return {
                        type: 'render',
                        name: component.name,
                        renderComponent: () => {
                            return (
                                <SelectComponent
                                    {...component}
                                    columnStyle={`${component.style ?? ''} ${component.type}`}
                                    onChange={setSelectedKey}
                                    triggerOnLoad={true}
                                />
                            );
                        }
                    };
                } else if (component.type === 'multi-select' && component.name === 'savvyy_values') {
                    return {
                        type: 'render',
                        name: component.name,
                        renderComponent: () => {
                            return (
                                <MultiSelectComponent
                                    key={index}
                                    {...component}
                                    columnStyle={`${component.style ?? ''} ${component.type}`}
                                    disabled={valueOptions.length === 0}
                                    options={valueOptions}
                                    defaultSelected={component.value}
                                    onChange={setSelectedValues}
                                />
                            );
                        }
                    };
                }

                return component;
            })
        });
    }, [schema, valueOptions, selectedValues]);

    return <FormHelper schema={updatedSchema} onSubmit={onSubmit} onCustomPayload={onCustomPayload} parentRef={parentRef} />;
};

export default CreateEditThinkerCondition;
